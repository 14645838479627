/* -------------Interface------------------------------------------ *
 * sl-card
 *   boder-top:  --sl-color-primary-500 
 *   h3          --sl-color-gray-600
 *   a        :  --sl-color-primary-500 
 *   
 */

sl-button[size="large"]::part(base) {
  box-shadow: 10px 22px 20px -8px var(--heading-shadow);
}


/* ------------- CARD ------------------------- */
sl-card {
	transform: translateY(0px) rotate(0deg);
	transition: transform 0.15s cubic-bezier(0.45, 0, 0.55, 1);

	&::part(base) {
		transition: box-shadow 0.15s cubic-bezier(0.45, 0, 0.55, 1);
		box-shadow: var( --sl-shadow-large );
		border-top: 5px solid var( --card-top-color );
/* var(--sl-color-primary-500); */
	}

	& h3, & p:last-of-type { margin-bottom: 0; }
	& h3 { color: var(--sl-color-gray-600);  }

	& a { color: var(--sl-color-primary-500) }

	& [slot="footer"] {
		display: flex; 
		justify-content: space-between; 
		align-items: center;
	}

	&:hover::part(base) { box-shadow: var(--sl-shadow-x-large); }
}
sl-card:not(:defined) {
		  display: flex;
			flex-direction: column;
			justify-content: space-between;
}

/* ------------- DIALOG ----------------------- */
sl-dialog { --body-spacing: 0.75rem 1.25rem; }

sl-dialog::part(title) {
  --header-spacing: 0.75rem 0 0.75rem 1.25rem;
  --sl-font-size-large: 2rem;
  font-family: var(--heading-font);
  font-weight: 700;
  color: var(--alternate-dark-color);
}

@media (max-width: 450px) {
  sl-dialog::part(close-button) {
    --header-spacing: 0.5rem;
  }

	sl-dialog::part(panel) {
		max-width: 96%;
	}
}

sl-input[size="medium"], sl-textarea[size="medium"] {
  --sl-input-font-size-medium: var(--sl-font-size-small);

  @media (--sl-breakpoint-xs) {
    /* ensure no weird zoom on mobile focus */
    --sl-input-font-size-medium: 16px;
  }
}


/* ------------- ICON ------------------------- */


p a sl-icon {
  margin-right: var(--sl-spacing-2x-small);
  margin-left: var(--sl-spacing-3x-small);
  vertical-align: -0.125em;
}



/* ------------- Alert ------------------------- */

sl-alert {
	margin-top: var( --sl-spacing-medium );
	margin-bottom: var( --sl-spacing-medium );
}
