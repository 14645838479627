header {
	display: grid;
	@media (--sl-breakpoint-xs) { 
		display: contents;
	}

	grid-template-areas: "hero";
	align-items: center;
/*	height: 65vh; */
	max-height: 600px;
	background-color: var(--sl-color-topo-50);
	overflow: visible;
/* 	margin-right: var(--sl-spacing-2x-large); */
}

header > * { grid-area: hero; }
header img {
	object-fit: cover;
	/* Image leicht nach links  und unten versetzen */
/*	object-position: 5vw -2vmin;  */
	height: min(60vh, 600px); 
	/* Bildbreite begrenzen */
/*	width: 80%;  */
	/* Bildobject selbst links anordnen  */
	justify-self:  end;
	transition: 180ms width ease-in;
	@media (--nav-fullsize) { 
	  width: 65%;
		object-position: 5vw -2vmin;  
 	} 
	@media (--sl-breakpoint-xs) {
		width: 100%;  
		object-fit: contain;
		height: auto;
	}
}

/*  Sonderbehandlungen für die Hero-Bilder */
/* body.home header img  {   margin-top: -100px;   }  */
body.systems header img ,
body.services header img { filter: opacity(0.5); }

header section {
	overflow: visible;
	/* links Abstand zum Seitenrand einfügen      */
	margin: var(--sl-spacing-medium)
	        0
	        var(--sl-spacing-2x-large)
					10%;    /* Abstand zum rand */
	/* Hintergrund ist etwas größer, als der Text */
/*	padding: 0.5rem 0.5rem 0.5rem 0; */
	padding-top:  var(--sl-spacing-small); 
	padding-bottom: 0;  /* var(--sl-spacing-small); */
	padding-left: var(--sl-spacing-medium);
	padding-right: var(--sl-spacing-medium);
	/* Nutze nur einen Teil des Platzes, Rest ist fürs Image */
	max-width: 35%;
	min-width: 25ch;
	height: 20ch; 
	z-index: 0;
	background-color: var(--sl-color-transparent-50);
	border-radius: 1rem;

@media(--sl-breakpoint-xs){ 
		margin: 0 ;
		height: auto;
		min-width: 95%;
	 }

	& h1 {
		font-size: var( --fluid-font-size-h3 );
		color: var(--link-color);
		font-weight: var(--sl-font-weight-semibold);
		margin: 0 0 var(--sl-spacinng-medium); }
	h1, h1 + p {

		@media(--sl-breakpoint-xs){ display: none  } 
	}
  & p { font-size:var(--fluid-font-size-p);  }
}
