/* ....................................................................... 
 *  main   wrapper  
 *         article 
 *         aside nav  
 * ....................................................................... */
body main wrapper  {
	padding-top: var(--sl-spacing-x-large);
	display: grid;
	/* 11.8.23:   1fr --> 0.5 fr damit wird das rechte Grid kleiner */
	grid-template-columns: minmax(0, 2.5fr) 1fr;
	/* gap: var( --sl-spacing-medium ); */
	margin-left: auto;
	margin-right: auto;
/*	max-width: calc(var(--max-content-width) + 2.25rem); */

	@media (--card-minimal) {
		grid-template-columns: 1fr;
		gap: 0;
	}

	& article {
		h3:before { counter-increment: h3; content: "\00A7" " " counter(h3) " "; }
		h3 { counter-reset: h4 }  
		h4:before { counter-increment: h4; content: "(" counter(h4) ") ";  }
		h4 { 
		    font-weight: var(--sl-font-weight-semibold);
				margin: 0;
				padding-top: var(--sl-spacing-small);
				counter-reset: topic;  
				color: var(--body-color);
			 margin-left: calc( var( --sl-spacing-large) * 1.5 );
			 text-indent: calc( var(--sl-spacing-large) * -1.5 );
		 	}
		ol.topic li:before {
		 counter-increment: topic;
	   content:  counter(topic, lower-alpha) ") ";
			}
		ol.topic li { 
		   padding-left: var(--sl-spacing-x-large);
			 margin-left: calc( var( --sl-spacing-large) * 1.1 );
			 text-indent: calc( var(--sl-spacing-large) * -1.1 );
		 }
		 
		ol {
  		  list-style-type: none;
				width: 100%;

	   	 li { display: block; }
       li:before {
/*			    counter-increment: subsection; */
				  content: "(" counter(section) ") ";
     		}
			}
		ul { list-style-type: square;
			   padding-left: var(--sl-spacing-large);
	   	}
		ul.nach-satz { list-style-type: square;
			   padding-left: var(--sl-spacing-3x-large);
	   	}
		& .subheader { 
			display: flex;
			justify-content: space-between;
		}
		& sl-card.article::part(base) { border-top: 0 solid #ffff; }
/*		@media (--nav-minimal) {  */
		/*	width: 100vw; */
			padding-left: var(--sl-spacing-medium);
			padding-right: var(--sl-spacing-medium);
/*		}  */
	}
	& aside { z-index: -1  }
	& aside  > nav { 
		font-size: var(--sl-font-size-small);
/*		padding: var(--sl-spacing-small);  */
		& div.table-container {    /* Weite der SL-Card-Container  */
		     width: var(--card-width) ;/* 100% */
				 margin: auto;  
				 & td { padding: 0 var(--sl-spacing-x-small); }
				 & table { margin: auto; }   /* zentriere Tabellen in cards */
			 }
		 }

	& aside > nav  > ul { 
			display: flex;
			flex-wrap: wrap;
			gap: var(--sl-spacing-3x-small );
			width: 30ch;
    }
	& aside > nav > ul > 	li { flex: 1 250px }
}
	
sl-card.blog { 
	width: 35ch;
}
sl-card.blog .wrapper { 
	display: flex;
	justify-content: space-between;
}
