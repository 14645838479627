/* Es wird ein minimal resposives, flexbox basiertes Tabellenlayout realisiert */
footer {

	background: 
		 	 radial-gradient(var(--sl-color-sky-900) 20% , var(--sl-color-orange-900) 140%, var(--sl-color-topo-50) 25%) ;
  color: var(--color-footer);
	margin: auto;
  margin-top: var(--sl-spacing-2x-large);
  & h4 {
    color: white;
    margin-top: var(--sl-spacing-small);
  }
	> layout-columns {
		display: flex;
		text-align: left;
		justify-content: space-around;
		align-items: center; 
		gap: var( --sl-spacing-x-large );
	/*	margin-bottom: var(--sl-spacing-2x-large);*/
		font-size: 90%;

		@media (min-width: 1200px) {
			font-size: calc(0.65rem + 0.4vw);
		}

		@media (--nav-minimal) {
			flex-direction: column;
			gap: 0;
		}


		> layout-column {
			padding: var(--sl-spacing-small) 0;

			@media (--nav-fullsize) {
				padding-top: calc(0.875rem - 0.5vw);

				&:first-child { padding-top: 0; }

			}
		}
	}
	> layout-columns.created { 
		justify-content: center;

		> layout-column {
			align-self: end;
			@media(--nav-minimal){ align-self: center; padding:0; margin: 0 }
		}
	}

  & sl-button[size="small"] {
    --sl-button-font-size-small: 0.8em;
    --sl-input-height-small: calc(1.5em + 15px);
  }
}
