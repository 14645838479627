:root {
  --body-size: 120%;

  --sl-font-sans: RedHatText, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --display-sans: RedHatDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
/*   --display-serif: DMSerif, Georgia, 'Times New Roman', serif;  */
  --display-serif: Lora, serif;  

	--fluid-font-size-p: clamp(var(--sl-font-size-medium), 4vw, var(--sl-font-size-large));
	--fluid-font-size-h3: clamp(var(--sl-font-size-large), 4vw, var(--sl-font-size-2x-large));
	--fluid-font-size-h1: clamp(var(--sl-font-size-large), 7vw, var(--sl-font-size-3x-large));
	--fluid-font-size-h2: clamp(5rem, 7vw, 7.5rem);

  --sl-border-radius-medium: 0.35rem;


  --spacing-gigantic: 12rem;
/*  --max-content-width: 80rem; */     /* estx: nicht auskommentiert */

  --h1-font-size: var(--sl-font-size-3x-large);
  --h2-font-size: var(--sl-font-size-2x-large);
  --h3-font-size: var(--sl-font-size-x-large);
  --h4-font-size: var(--sl-font-size-large);

	@media (--sl-breakpoint-xs) { --body-size: 100%; } 
}

@keyframes fadeIn { 0% { opacity: 0; } 100% { opacity: 1; } }

@keyframes fadeInOut {
  0% { opacity: 0; }
  18% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes swingBackAndForth {
  0% { transform: rotate(-7deg); }
  4.5% { transform: rotate(-16deg); }
  11.5% { transform: rotate(-7deg); }
  18% { transform: rotate(-10deg); }
  25% { transform: rotate(-7deg); }
  30% { transform: rotate(-8deg); }
  37% { transform: rotate(-7deg); }
}

*[hidden] { display: none; }


html {
  font-size: var(--body-size);
}

body {
  margin:      auto;
  padding:     0;
	max-width:   var(--max-body-width);
  background:  var(--color-body-background); 
  color:       var(--color-body-text);
  font-family: var(--sl-font-sans);
  font-weight: var(--sl-font-weight-normal);
  line-height: var(--sl-line-height-dense);
}

/* Silbentrennung  für Absätze aktivieren */
p, li {
		hyphens: auto;
		hypenate-limit-chars: 5 2 2;  /* Minimale Wortlänge, minimale silbenkänge vorher +  nachher */
		hypenate-limit-lines: 3;      /* hypenate wird noch kaum unterstützt                        */
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		-webkit-hypenate-limit-chars: 5 2 2;
		-ms-hypenate-limit-chars: 5 3 3;
		-ms-hypenate-limit-lines: 2;
		-webkit-hypenate-limit-lines: 3;
	}
	/* hängender Einzug */
/* li { text-indent:  calc( var(--sl-spacing-medium) *-1   ) ; } */
h1, h2, h3, h4, h5 {
  font-family: var(--display-sans);
  font-weight: var(--sl-font-weight-bold) ;  /* 900;  */
  letter-spacing: var(--sl-letter-spacing-normal); /*  loose, normal dense  */
  color: var(--sl-color-neutral-900);
  outline: none;
  word-break: break-word;

  &.serif {
    font-family: var(--display-serif);
    font-weight: 600;
  }

  &.colorful {
    color: var(--sl-color-neutral-800);
  }
}

h1 {
  font-size: var(--h1-font-size);
  &.serif {
    font-size: calc(var(--h1-font-size) + 0.2em);
  }
}

h2 {
  font-size: var(--h2-font-size);
  &.serif {
    font-size: calc(var(--h2-font-size) + 0.2em);
  }
  @media (--sl-breakpoint-xs) {
    position: relative;
    overflow: hidden;
  }
}

h1, h2 , h3, h4 {
  line-height: var(--sl-line-height-dense); 
  margin: var(--sl-spacing-large) 0 
	        var(--sl-spacing-small);
}

h3 { 
	font-size: var(--h3-font-size); 
  &.serif {
    font-size: calc(var(--h3-font-size) + 0.2em);
  }
}

h4 { font-size: var(--h4-font-size); }

h1, h2, h3, h4, footer { text-align: left; }

ul, ol, blockquote, figure {
  margin-top: 0;
  margin-bottom: var(--sl-spacing-x-small);   /* estx: large */
  word-break: break-word;
}

a {
  color: var(--link-color);
  text-underline-offset: 0.15em;
  text-decoration-color: var(--sl-color-amber-300);
}

hr {
  border: none;
  border-top: 2px dashed var(--sl-color-neutral-200);
  margin: var(--sl-spacing-2x-large) auto;
}
/* estx: nicht auskommentiert */
/* nav, body > footer { padding: var(--sl-spacing-2x-large);  } */
code {
	color: var(--sl-color-topo-700 );
	padding: 0 var(--sl-spacing-3x-small );
}
svg-wrapper, nav sl-icon {
  transform: translate(0,0); /* fix a Safari display bug with transitions */
}
svg-wrapper, svg-wrapper svg { display: block; }

main {
  margin: auto;
  min-height: calc(100vh - 600px);
}

main-content { display: block; }

section-wrapper {
  display: block;
  overflow: hidden;

  &[invert] { background: var(--color-blackish);  }
  &[size="large"] {
    font-size: 115%;
    text-align: center;
  }
}
section-wrapper[invert], footer {
  color: var(--color-dark-gray);  /* --color-wintermint); */

  & h2 { color: var(--color-brick); }

  & a {
    color: var(--sl-color-amber-400);
    text-decoration-color: var(--color-brick);
  }
}

section {
  margin: 0 auto;
  max-width: var( --max-content-width );
	padding: 0  var(--sl-spacing-large);   /* von estx */
	@media( --sl-breakpoint-xs ){ padding: var(--sl-spacing-large) var(--sl-spacing-small);}  /* von estx */
	& p {
   	margin-bottom: var(--sl-spacing-small);
		@media( --sl-breakpoint-xs ){ font-size: var( --sl-font-size-medium ); }
	}
 
  padding: var(--sl-spacing-large) var(--sl-spacing-small);   /* nur still */
  @media ( --sl-breakpoint-xs ) {                             /* nur still */    
		padding: var(--sl-spacing-small) var(--sl-spacing-small); /* nur still */
  }

  & ul, & ol {
    margin-left: var(--sl-spacing-x-large);
    margin-right: 0;  /* von estx */
    padding-left: 0;  /* von estx */
    padding-right: 0;  /* von estx */

    & > li {
      margin: 0 0 var(--sl-spacing-small);

      &:last-of-type { margin-bottom: 0; }
    }
  }
  & ul {
    list-style-type: square;
    list-style-position: inside;
  }
}

.footnotes {
	margin-top: var(--sl-spacing-3x-large);
	margin-bottom: var(--sl-spacing-2x-large);

/*	margin-left: 50px; */

	&::before { 
		content: "";
		position: absolute;
		height: 1px;
		width: 66px;
		margin-top: -10px;
		border-bottom: 1px solid #d7d7d7;
	}

	& ol {
	  margin-left: var(--sl-spacing-large);
		line-height: var(--sl-line-height-dense);
		margin-bottom: var(--sl-spacing-large);
		list-style-position: outside;
		font-family: inherit;
		font-size: var(--sl-font-size-x-small);

		}
}

.footer-wave {
  margin-bottom: -1px; /* needed to fix gap on Edge (Chrome?) */

  & svg {
    transform: scale(-1, 1);

    & path { fill: var(--color-dark-gray) !important; }
  }
}


html:not([loaded]) .fade-in-animation {
  opacity: 0;
  animation: fadeIn 0.8s 0.4s;
  animation-fill-mode: forwards;
}

.fade-in-always {
  opacity: 0;
  animation: fadeIn 0.8s;
  animation-fill-mode: forwards;
}

p.heading-icon {
  text-align: center;
  font-size: 250%;
  margin-bottom: 0;
  color: var(--sl-color-neutral-400);
}

section-wrapper[invert] p.heading-icon { color: var(--sl-color-primary-500); }


