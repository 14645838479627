cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20ch, auto));
	column-gap: var(--sl-spacing-2x-large);
	row-gap:  var(--sl-spacing-2x-large);
  justify-content: space-between;
	margin:   var(--sl-spacing-2x-large)  ;
	z-index: 100;

     font-size: var(--fluid-font-size-p); 
	/*		@media (--sl-breakpoint-xs) { grid-template-columns: 1fr; } */

	& sl-card { 
/*		height: 50ch;  */
/*		width: 100%;     */
/*		::part(body){ height: 60ch; } */

		& h3{ 	text-align: center } }

	& ol { 
	     list-style-type: upper-roman; 
			 list-style-position: inside;
			 text-align: left;
			 padding:  var(--sl-spacing-medium);
			 padding-bottom: var(--sl-spacing-small);
		 } 
	& ol.points { 
	      list-style-type: decimal; 
				padding-left: var(--sl-spacing-medium);
		  	} 
	& p {  
			 padding: var(--sl-spacing-large) ;
padding-bottom: var(--sl-spacing-small);

		 }
	> li { 
  list-style-type: none;
}


	

	@media( --sl-breakpoint-xs ){
		margin: var(--sl-spacing-2x-small);
	}
	@media( --sl-breakpoint-sm ){
		margin: var(--sl-spacing-small);
	}

}

/*
 cards.blog { 
	margin-top: var( --sl-spacing-2x-large );
}
*/ 
cards.card-grid {
/*@media( --sl-breakpoint-xs ) { */

 @media( min-width: 650px ) { 
		grid-template-areas: none;
/*		grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr)); */
		& :nth-of-type(3) { grid-column: 1 / -1; } 		
		}
 @media( --hero-fullsize) { 
  /* 	width: 100vw;  */
	  grid-template-columns: repeat(auto-fit, minmax(25ch, 1fr));
		& :nth-of-type(3) { grid-column: auto  }
		}  


	& element.angestellte {
		padding: var(--sl-spacing-x-small);
		text-align: center;
		& sl-avatar { 
			justify-self: center;
			--size: 5rem;
			padding-top:  var(--sl-spacing-medium);
			padding-bottom:  var(--sl-spacing-medium);
		}
	}
	& element.manager {
		display: grid;
		text-align: center;
		grid-template-areas: "manager";
		place-items: center;
		padding: var( --sl-spacing-small ) var(--sl-spacing-large);

		& > * { grid-area: manager; }
		& sl-avatar { 
			justify-self: start;
			--size:  8rem /*var( calc( --sl-spacing-large, *6 ) )*/ ;
			& ::part(image){ border: thick double #32a1ce;}
		}

		& detail {
			z-index: 1;
			overflow: visible;
			justify-self: end;
			padding: var(--sl-spacing-x-small);
			background-color: var(--sl-color-transparent);
			border-radius: 1rem;
		}
	}     /* element  */
}

/* sl-card::part(base) { height: 50ch;  } */
cards.blog > sl-card {
	--border-width: 0;
	/*	--card-primary: var(--sl-color-green-200); /* #ff6f59; */
	/*	--card-primary-dark: var(--sl-color-blue-400); /* #c2422f; */
	text-align: left;
/*	padding-left: calc( var(--sl-spacing-x-large) * 6); */
/* 	text-indent: calc( var(--sl-spacing-x-large)  * -1); */
/*	height: 50ch; */  /* for equal height cards */
	box-shadow: inset 4px 4px 4px  var(--hero-primary-dark);  
	box-shadow: var(--sl-shadow-x-large);
/* 	background-color:  var(--blog-background);   */

	& ul {
		& li { 
			list-style-type: square; 
			list-style-position: outside;
			margin-left: var(--sl-spacing-large);
			text-align: left
		}
	}

	::part(header){
	}
	::part(body){
/*		height: 100%;*/
		background-color: green;
	}
	::part(base){

		background: radial-gradient(var(--sl-color-amber-200) 25%, var(--sl-color-red-50) 100%); 
		position: relative;
		box-shadow: inset 4px 4px 4px  var(--action-color);  
		box-shadow: var(--sl-shadow-x-large);
	background-color:  var(--be-card-background-color); 
	}

	& sl-button > a:hover{ color:  #000 }
}
	sl-card.blog [slot='header'] {
	background-color:  var(--be-card-background-color); 
}

	sl-card.blog [slot='footer'] {
		    display: flex;
				flex-direction: row;
				justify-content: start;
				align-items: center;
	}

cards.card-grid {

	--card-primary: var(--sl-color-green-200); /* #ff6f59; */
	--card-primary-dark: var(--sl-color-blue-400); /* #c2422f; */

  list-style-type: none;
  padding-top: var(--sl-spacing-3x-large); 
  padding-bottom: var(--sl-spacing-3x-large); 
	/* padding-left: var(--sl-spacing-2x-large); auskommentiert damit die volle Seitenbreite genutzt wird */
/*	padding-right: var(--sl-spacing-2x-large); auskommentiert damit die volle Seitenbreite genutzt wird */
	/* Hintergrund des Grids!  */
/*	background: red;        */

  & li {
    margin: 0 !important;
  }

  & sl-card {
    --padding: 2rem 1.25rem;
		--border-color: var(--sl-color-teal-700);
		--border-width: var(--sl-spacing-3x-small);
		--border-radius: var(--sl-border-radius-large);
    height: 100%;
    width: 100%;
		z-index: 1000;
    text-align: justify;
	/*	box-shadow: var(--sl-shadow-x-large);  */
			box-shadow: inset 40px 40px 40px  var(--sl-color-blue-400);  

		/* Hintergrundfarbe außerhalb der runden Ecken */
		/* background: green; */ 

    &::part(base) {
      height: 100%;
			background: radial-gradient(var(--hero-primary) 85%, var(--hero-primary-dark) 10%); 
			position: relative;
		/* 	box-shadow: inset 4px 4px 4px  var(--hero-primary-dark);  */
		box-shadow: var(--sl-shadow-x-large);
/*			color: var(--sl-color-amber-300);  */
			&::after {
						 content: "";
						 position: absolute;
						 top: 0;
						 left: 0;
						 width: 100%;
						 height: 100%;
						 z-index: 0;
					 
			}
	   &::after {
		     background-position: 0 0;
		     background-size: 10px 10px;
		     background-image: linear-gradient(
		       -45deg, rgba(0, 0, 0, .045) 25%,
		       transparent 25%, transparent 50%,
		        rgba(0, 0, 0, .045) 50%, rgba(0, 0, 0, .045) 75%,
		       transparent 75%, transparent);
				 }
			}
		 /*  Überschriften der Cards                     */
			&::part(header), &::part(footer) {
				padding: 1rem 1.25rem;
			color: var(--sl-color-amber-400);
			font-size: var(--sl-font-size-x-large);
			}

			&::part(body) {
				flex-grow: 1;
			}

			& [slot="header"] h3 {
				margin: 0;
			}

			& [slot="footer"] {
				display: flex; 
				justify-content: space-between; 
				align-items: center;

				& :only-child {
					margin: 0 auto;
				}

				& img {
					width: 50px;
					border-radius: 50px;
					vertical-align: middle;
					margin-right: 0.3em;
				}
			}
		}   /* closing &sl-card */
}

.card-category-2 ul { padding: 0; }
    
.card-category-2 ul li {
	    list-style-type: none;
			    display: inline-block;
					    vertical-align: top;
	/*    margin: 10px var(--sl-spacing-large);*/
			
}

.card-category-2 {
	    font-family: sans-serif;
			    margin-bottom: 45px;
					    text-align: center;
							
}
.card-category-2 div { display:inline-block; }

.card-category-1 > div, .card-category-2 > div:not(:last-child) {
	        margin: 10px 5px;
					        text-align: left;
									    
}


  /* Image Card */
.img-card {
/*	width:30ch; */
/*	height: 30rem; */
	@media(--sl-breakpoint-xs){ width: 100%  }
	@media(--sl-breakpoint-sm){ width: 100%  }
	position: relative;
	border-radius: 5px;
	text-align: left;

	box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);

}

.img-card .card-image {
	position: relative;
	margin: auto;
	overflow: hidden;
	border-radius: 5px 5px 0px 0px;
	height: 300px;

}

.img-card .card-image img {
	width:100%;
	border-radius: 5px 5px 0px 0px;
	transition: all 0.8s;
/*  object-fit: cover; */
/*	height: 30rem; */

}

.img-card .card-image:hover img {
	transform: scale(1.1);

}        

.img-card .card-text {
	padding: 0 15px 15px;
	line-height: 1.5;   

}

.img-card .card-link {
	padding: 20px 15px 30px;
	width: -webkit-fill-available;

}

.img-card .card-link a {
	text-decoration: none;
	position: relative;
	padding: 10px 0;

}

.img-card .card-link a:after {
	top: 30px;
	content: "";
	display: block;
	height: 2px;
	left: 50%;
	position: absolute;
	width: 0;

	transition: width 0.3s ease 0s, left 0.3s ease 0s;

}

.img-card .card-link a:hover:after { 
	width: 100%; 
	left: 0; 

}        
/*
.img-card.iCard-style1 .card-title {
	position: absolute;
	font-family: 'Open Sans', sans-serif;
	z-index: 1;
	top: 10px;
	left: 10px;
	font-size: 30px;
	color: #fff;

}
*/
.img-card.iCard-style1 .card-title {
	bottom: var(--sl-spacing-large);
	font-size: var(--sl-font-size-x-large);
	font-weight: var(--sl-font-weight-bold);

	color: var(--be-logo-color);
	position: absolute;
	width: 100%;
	font-family: 'Roboto', sans-serif;
	z-index: 1;
	text-align: center;

	/*	backdrop-filter: brightness(150%); */
  /*  backdrop-filter: sepia(45%);       */
	/*  text-shadow: 3px 4px var(--sl-color-topo-200); */
			text-shadow:
			  0 0 10px var(  --sl-color-neutral-0 ),
			  0 0 20px var(  --sl-color-neutral-0 ),
			  0 0 30px var(  --sl-color-neutral-0 ),
			  0 0 40px var(  --sl-color-neutral-0 ),
			  0 0 70px var(  --sl-color-topo-300 ),
			  0 0 80px var(  --sl-color-topo-300 ),
			  0 0 100px var( --sl-color-topo-300 ),
			  0 0 150px var( --sl-color-topo-300 );

}
.card-fix  img { 
	object-fit: cover;
	height: 20rem;

}

.img-card.iCard-style1 .card-text {            
	color: #795548;

}

.img-card.iCard-style1 .card-link a {
	color: #FF9800;                

}

.img-card.iCard-style1 .card-link a:after {            
	background: #FF9800;

}
