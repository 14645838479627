body.home main header, body.kraftwerk main header { 
	margin: auto;                           /* center if dimension > max-width  */

	@media(--nav-fullsize) { 
		margin-right: var(--sl-spacing-2x-large);
	}
}

body.home  nav, body.kraftwerk nav { 
		@media(--sl-breakpoint-xs){ padding: 0; }
}


body.home div.intro , body.kraftwerk div.intro {
	padding: var(--sl-spacing-2x-large) var(--sl-spacing-2x-large) ;
	& p { 
	 	font-size: var(--fluid-font-size-p);
		padding:  var(--sl-spacing-2x-small) var(--sl-spacing-medium);
		@media(--nav-minimal){  
			padding:  var(--sl-spacing-2x-small) var(--sl-spacing-small);
		}
	}
	ul { 
      list-style-type: disc;
			list-style-position: outside;
			font-size: var(--fluid-font-size-p);
			padding-left: var(--sl-spacing-medium);
			margin: 0 var(--sl-spacing-large);
			@media( --sl-breakpoint-xs ) {  margin: 0; }
		}
	@media(--nav-minimal){  
			& sl-alert { 
				margin: 0;
				padding: 0; }
			& sl-icon { display: none; width: 0 }
		}


	@media(--nav-minimal){  
/*		padding: var(--sl-spacing-2x-large);  */
    padding: var(--sl-spacing-3x-small);
		 }
}
body.kraftwerk  div.intro{ 
	    padding:  var(--sl-spacing-2x-large) 
                var(--sl-spacing-2x-large)
			       	 0
                var(--sl-spacing-2x-large);

			@media( --sl-breakpoint-xs ) {  padding: var(--sl-spacing-x-small); }
									     	 }
	
body.kraftwerk cards { 
  margin: 0  var(--sl-spacing-2x-large);
	@media( --sl-breakpoint-xs ) { margin: 0  var(--sl-spacing-2x-small);		    }
 } 

logo {
	   font-family: var(--display-serif);
	   letter-spacing: var(--sl-letter-spacing-loose);
	   color: var(--be-logo-color);
		 font-weight: var(--sl-font-weight-bold);
		 font-size: larger;
}
