/* https://codepen.io/NielsVoogt/pen/MbMMxv  */
.timeline-item {
	padding: 3em 2em 2em;
	position: relative;
	color: rgba(0, 0, 0, 0.7);
	border-left: 2px solid rgba(0, 0, 0, 0.3);

}
.timeline-item p {
	font-size: var(--sl-font-size-large);
}
.timeline-item h3 {
	padding-left: var(--sl-spacing-medium);
}
.timeline-item::before {
	content: attr(date-is);
	position: absolute;
	left: 2em;
	font-weight: bold;
	top: 1em;
	display: block;
	font-weight: var(--sl-font-weight-dense);
	font-size: var(--sl-font-size-large);

}
.timeline-item::after {
	width: 10px;
	height: 10px;
	display: block;
	top: 1em;
	position: absolute;
	left: -7px;
	border-radius: 10px;
	content: "";
	border: 2px solid rgba(0, 0, 0, 0.3);
	background: white;

}
.timeline-item:last-child {
	-o-border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0)) 1 100%;
	border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0)) 1 100%;

}
